/* General styling */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f7f8fc;
    margin: 0;
    padding: 0;
}

/* Register Section */
.register {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.registerform {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

h2 {
    margin-top: 0;
    color: #333;
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
}

.inputbox {
    position: relative;
    margin-bottom: 30px;
}

.inputbox input, 
.inputbox textarea,
.inputbox select {
    width: 100%;
    padding: 10px;
    background-color: #f7f8fc;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    font-size: 16px;
}

.inputbox textarea {
    resize: vertical;
    min-height: 100px;
}

.inputbox span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    left: 15px;
    color: #777;
    transition: 0.3s;
    pointer-events: none;
}
.inputbox input:focus,.inputbox select:focus {
    outline: 1px solid #007bff;
}

/* When the input is focused OR there's content in it, apply the floating label effect */
.inputbox input:focus + span, 
.inputbox input:not(:placeholder-shown) + span, 
.inputbox select:focus + span {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: #555;
    background-color: #ffffff;
    padding: 2px 5px;
    border-radius: 2px;
}

/* Set the default state for the label to be inside the input */
.inputbox span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    left: 15px;
    color: #777;
    transition: 0.3s;
    pointer-events: none;
}

.inputbox select + span {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: #555;
    background-color: #ffffff;
    padding: 2px 5px;
    border-radius: 2px;
}
.inputbox input:not(:focus):placeholder-shown + span {
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 16px;
    color: #777;
}
.inputbox input[type="submit"] {
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.inputbox input[type="submit"]:hover {
    background-color: #0056b3;
}

/* For responsiveness */
@media screen and (max-width: 520px) {
    .registerform {
        padding: 20px;
    }
}

.rtl {
    direction: rtl;
    text-align: right;
}

.rtl input::placeholder, .rtl select::placeholder {
    text-align: right;
}

.rtl .inputbox span {
    right: 15px;
    left: auto;
}
.rtl .inputbox input:focus + span,
.rtl .inputbox input:not(:placeholder-shown) + span,
.rtl .inputbox select:focus + span {
    right: 10px;
    left: auto;
}